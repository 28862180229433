import Button from '../../Util/Button';
import Form from '../../Search/Form';
import { useState } from 'react';
import Grid from '../../Util/Grid';
import GridColumn from '../../Util/GridColumn';

import BusinesslineTypeSelect from '../Search/BusinesslineTypeSelect';
import BusinesslineSelect from '../Search/BusinesslineSelect';
import ServiceSelect from '../Search/ServiceSelect';
import TurnoversSelect from '../Search/TurnoversSelect';
import CityOfficeSelect from '../Search/CityOfficeSelect';
import CategorySelect from '../Search/CategorySelect';
import KeywordsTextInput from '../Search/KeywordsTextInput';
import NumberTextInput from '../Search/NumberTextInput';
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from 'react-i18next';

export default function OrganizationsSearch(props) {
  
  const { t, i18n } = useTranslation();

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  var is_industry  = props.filters.is_industry.value;
  var is_company_form  = props.filters.is_company_form.value;
  var is_services  = props.filters.is_services.value;
  var is_municipality  = props.filters.is_municipality.value;
  var is_turnover_class  = props.filters.is_turnover_class.value;
  var is_zip_code  = props.filters.is_zip_code.value;
  var categoryFillterInput = '';
  if(is_company_form != undefined && is_company_form == 1){
    categoryFillterInput = <div className="col-6 col-sm-auto col-md col-xl-auto"><CategorySelect options={props.filters.categories} checked={props.state.categories}/></div>;
  }

  var businesslineSelectFillterInput = '';
  if(is_industry != undefined && is_industry == 1){
    businesslineSelectFillterInput = <div className="col-6 col-sm-auto col-md col-xl-auto"><BusinesslineSelect options={props.filters.businesslines} checked={props.state.businesslines}/></div>;
  }

  var turnoversSelectFillterInput = '';
  if(is_turnover_class != undefined && is_turnover_class == 1){
    turnoversSelectFillterInput = <div className="col-auto col-md col-xl-auto"><TurnoversSelect options={props.filters.turnovers} checked={props.state.turnovers}/></div>;
  }

  var serviceSelectFillterInput = '';
  if(is_services != undefined && is_services == 1){
    serviceSelectFillterInput = <div className="col col-sm-auto col-md col-xl-auto"><ServiceSelect options={props.filters.services} checked={props.state.services}/></div>;
  }

  var cityofficeSelectFillterInput = '';
  if(is_municipality != undefined && is_municipality == 1){
    cityofficeSelectFillterInput =    <div className="col-6 col-sm-auto col-md col-xl-auto"><CityOfficeSelect options={props.filters.citys} checked={props.state.cityoffice}/></div>;
  }

  var zipcodeFillterInput = '';
  if(is_zip_code != undefined && is_zip_code == 1){
    zipcodeFillterInput =   <div className="col-6 col-sm"><NumberTextInput name="pincodes" value={props.state.postinumero} className="form-control" placeholder={t('zip_code')} /></div>;
  }

  return (
    <Form className="organization-filters" onSubmit={props.onSubmit}>
      <div className="row g-3">
        {businesslineSelectFillterInput}
        {categoryFillterInput}
        {turnoversSelectFillterInput}
        {serviceSelectFillterInput}
        <div className="col-12 d-lg-none mt-0"></div>
        {cityofficeSelectFillterInput}
        <div className="col-12 d-none d-lg-block d-xl-none mt-0"></div>
        {zipcodeFillterInput}
        {/*<div className="col-auto">
                  <BusinesslineTypeSelect options={props.filters.businesslineTypes} checked={props.state.businesslineTypes}/>
                </div>*/}
        <div className="col-12 d-md-none d-xl-block mt-0"></div>
        <div className="col col-lg">
          <KeywordsTextInput name="keywords" value={props.state.keywords} className="form-control" placeholder={t('search_terms')} />
        </div>
        <div className="col-auto">
          <Button type="submit" className="btn btn-primary">{t('search')}</Button>
        </div>
        <div className="col-auto">
          <Button type="clear" onClick={props.OnReset} className="btn btn-primary">{t('reset')}</Button>
        </div>
      </div>
    </Form>
  );
};
