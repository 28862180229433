import React from 'react';

import './styles/bf-bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles/style.css'
import Main from './Components/Layout/Main';
import Header from './Components/Layout/Header';
import Content from './Components/Layout/Content';
import Footer from './Components/Layout/Footer';

import Home from './Routes/Home';

export default function App(props) {
  return(
    <Main>
      <Content>
        <Home />
      </Content>
      <Footer />
    </Main>
  );
};
