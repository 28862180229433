import React, {useState} from 'react';

export default function Checkbox(props) {
  const [checked, setChecked] = useState(props.checked ? props.checked : false);

  const handleChange = (event) => {
    setChecked(!checked);
  };

  return(
    <label className="form-check">
      <input
        className="form-check-input"
        name={props.name}
        type="checkbox"
        value={props.value ? props.value : 1}
        checked={checked}
        onChange={handleChange}/>
      <span className="form-check-label">{props.label}</span>
    </label>
  );
};
