import { useTranslation } from 'react-i18next';

export default function Hours(props) {
  let items = props.content.map((item, index) => { 
    return <li key={index}>{item}</li>;
  });

  const { t, i18n } = useTranslation();

  let list = items.length ? <ul>{items}</ul> : '';
  
  return(
    <div className="organization__info organization__hours">
      <h3 className="organization__info-title">{t('visitinghours')}</h3>
      {list}
    </div>
  );
}
