import { useTranslation } from 'react-i18next';

export default function ContactPerson(props) {

  const { t, i18n } = useTranslation();
  var persons = [];


  props.content.forEach(function(person) {
    var mailLink = 'mailto:'+person.email;
    var phoneLink = 'tel:'+person.phone;
    
    persons.push(
      <div className="col-12 mb-3">
        <div>{person.name}</div>
        <div><a href={phoneLink} target="_blank">{person.phone}</a></div>
        <div><a href={mailLink} target="_blank">{person.email}</a></div>
      </div>
      );
});

  return(
    <div className="organization__info organization__contact-person">
      <h3 className="organization__info-title">{t('contactperson')}</h3>
      {persons}
    </div>
  );
}
