export default function Angle(props) {
  let className = 'angle';
  if ( props.back ) {
    className += ' back';
  }

  return(
    <svg className={className} width="1em" height="1em" viewBox="0 0 16 16" aria-hidden="true">
      <path fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.75,7.994h12.495L8.975,13.25 M8.979,2.75l5.271,5.234"/>
    </svg>
  );
};
