import Grid from '../../Util/Grid';
import GridColumn from '../../Util/GridColumn';

import Description from './Parts/Description';
import Hours from './Parts/Hours';
import Services from './Parts/Services';
import { useTranslation } from 'react-i18next';

export default function About(props) {
  const {organization} = props;

  const { t, i18n } = useTranslation();

  var columns = [];
  var column2 = [];

  if ( organization ) {

    if (organization.attributes.hours.length) {
      columns.push(
        <div className="col-12 col-md-3" key="hours">
          <Hours content={organization.attributes.hours} />
        </div>
      );
    }

    if ( organization.attributes.description.length ) {
      columns.push(
        <div className="col-12 col-md-6" key="description">
          <Description content={organization.attributes.description} />
        </div>
      );
    }

    if ( organization.attributes.services.length ) {
      columns.push(
        <div className="col-12 col-md-3" key="services">
          <Services content={organization.attributes.services} />
        </div>
      );
    }

    if (organization.attributes.hours.length) {
      column2.push(
        <div className="accordion-item" key="hours">
          <h2 className="accordion-header d-xl-none">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOrg_1" aria-expanded="false" aria-controls="collapseOrg_1">{t('visitinghours')}</button>
          </h2>
          <div id="collapseOrg_1" className="accordion-collapse collapse d-xl-none" data-bs-parent="#accordionOrganization">
            <div className="accordion-body">
              <Hours content={organization.attributes.hours} />
            </div>
          </div>
        </div>
      );
    }

    if ( organization.attributes.description.length ) {
      column2.push(
        <div className="accordion-item" key="description">
          <h2 className="accordion-header d-xl-none">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOrg_2" aria-expanded="false" aria-controls="collapseOrg_2">{t('informationaboutus')}</button>
          </h2>
          <div id="collapseOrg_2" className="accordion-collapse collapse d-xl-none" data-bs-parent="#accordionOrganization">
            <div className="accordion-body">
              <Description content={organization.attributes.description} />
            </div>
          </div>
        </div>
      );
    }

    if ( organization.attributes.services.length ) {
      column2.push(
        <div className="accordion-item" key="services">
          <h2 className="accordion-header d-xl-none">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOrg_3" aria-expanded="false" aria-controls="collapseOrg_3">{t('services')}</button>
          </h2>
          <div id="collapseOrg_3" className="accordion-collapse collapse d-xl-none" data-bs-parent="#accordionOrganization">
            <div className="accordion-body">
              <Services content={organization.attributes.services} />
            </div>
          </div>
        </div>
      );
    }

  }

  return(
    columns.length ? <div className="organization__section organization__about">
      <div className="row">{columns}</div>
      <div className="accordion" id="accordionOrganization">
        {column2}
      </div>
    </div> : ''
  );
}
