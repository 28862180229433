import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import config from './config.json';


loadStyleSheet();
ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );

// (function(){
//     debugger
    // const globalName = window['BusinessFinderObject'];

    // if ( ! window[globalName] ) {
    //     return;
    // }else{
    //    const globalName =   1 * new Date();
    // }

    // if ( window[globalName].q.length === 0 ) {
    //     return;
    // }

    // for (var i = 0; i < window[globalName].q.length; i++) {
    //     let args = Array.from(
    //         window[globalName].q[i]
    //     );

    //     let callbackName = args.shift();
    //     switch (callbackName) {
    //         case 'results':
    //             results(...args);
    //             break;
    //         default:

    //     }
    // }

    function results(elementId) {
      var rootElement = document.getElementById('root');
      if ( ! rootElement ) {
        return;
      }

      loadStyleSheet();
      initApp(rootElement);
    }

    function initApp(rootElement) {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        rootElement
      );
    }

    function loadStyleSheet() {
      var head  = document.getElementsByTagName('head')[0];
      var link  = document.createElement('link');

      link.setAttribute('id', 'businessfinderStyles');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', [config.url, config.assets.base, config.assets.version, config.assets.css].join('/')+'?v='+Date.now());
      link.setAttribute('media', 'all');

      head.appendChild(link)
;
    }

// })();