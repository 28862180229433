import config from '../config.json';

export default function fetchOrganizationLatLog(address_id, latitude, longitude) {

  let url = [config.url, config.api.base, config.api.version,config.api.endpoints.index, config.api.endpoints.organizationLatLog].join('/');
  url += '?addresses_id=' + address_id;
  url += '&latitude=' + latitude;
  url += '&longitude=' + longitude;

  return fetch(url,{method: 'GET'});
}