import Grid from '../../Util/Grid';
import GridColumn from '../../Util/GridColumn';

import ContactPerson from './Parts/ContactPerson';
import Email from './Parts/Email';
import Map from './Parts/Map';
import Phone from './Parts/Phone';
import PostalAddress from './Parts/PostalAddress';
import BranchAddress from './Parts/BranchAddress';
import SocialMedia from './Parts/SocialMedia';
import VisitingAddress from './Parts/VisitingAddress';
import Website from './Parts/Website';
import Telephone from './Parts/Telephone';
import Turnover from './Parts/Turnover';
import Profit from './Parts/Profit';
import CompanyRating from './Parts/CompanyRating';
import { useTranslation } from 'react-i18next';

export default function Details(props) {
  const {organization} = props;
  const { t, i18n } = useTranslation();

  var visitingAddresses = [],
      branchAddresses = [],
      postalAddresses = [];

  var persons,
      phone,
      email,
      website,
      telephone,
      subName,
      turnover,
      profit,
      company_rating,
      socialMedia = organization.attributes.social;
      subName = organization.attributes.sub_name;
      
  if ( organization ) {
   var logo = props.organization.logo;
    // Addresses
    for (let i = 0; i < organization.attributes.addresses.length; i++) {
      switch (organization.attributes.addresses[i].type.type) {
        case 'visiting':
          visitingAddresses.push(organization.attributes.addresses[i]);
          break;
        case 'branch':
          branchAddresses.push(organization.attributes.addresses[i]);
          break;
        case 'postal':
          postalAddresses.push(organization.attributes.addresses[i]);
          break;
      }
    }

    // Contacts
    for (let i = 0; i < organization.attributes.contacts.length; i++) {
      switch (organization.attributes.contacts[i].type.type) {
        case 'website':
          website = organization.attributes.contacts[i];
          break;

        case 'mobile-phone':
          phone = organization.attributes.contacts[i];
          break;

        case 'email':
          email = organization.attributes.contacts[i];
          break;

        case 'telephone':
          telephone = organization.attributes.contacts[i];
          break;   
      }
    }

    // Finance Data
    turnover = organization.attributes.organization_financial_info.turnover;
    profit = organization.attributes.organization_financial_info.operating_profit;
    company_rating = organization.attributes.financial_company_ratings.company_rating;

  }
  
  var columns = [];

  if ( logo ) {
    columns.push(
      <div className="col-12 d-sm-none" key="logo">
        <div className="organization__images ologo-top">
          {logo ? 
            <img
              src={logo}
              alt=""
            />: ''}
        </div>
      </div>
    );
  }

  var column2 = [];
  // console.log(props.organization);
  // if ( props.organization.attributes.name) {
  //   column2.push(<h3 className="organization__info-title">{props.organization.attributes.name}</h3>);
  // }

  // if ( props.organization.attributes.name) {
  //   column2.push(<div>Y-tunnus: {props.organization.id}</div>);
  // }


  // if ( props.organization.attributes.name) {
  //   column2.push(<div>Toimiala: </div>);
  // }

  // if ( props.organization.attributes.name) {
  //   column2.push(<div>Liikevaihto (tuhatta €): {turnover}</div>);
  // }

  // if ( telephone ) {
  //   column2.push(<Telephone key="telephone" content={telephone} />);
  // }

  // if ( website ) {
  //   column2.push(<Website key="website" content={website} />);
  // }

  // if ( phone ) {
  //   column2.push(<Phone key="phone" content={phone} />);
  // }

  // if ( email ) {
  //   column2.push(<Email key="email" content={email} />);
  // }

  if ( props.organization.attributes.name || website || phone || email ) {
    column2.push(
      <div className="organization__info organization__addresses" key="contact_info">
        <h3 className="organization__info-title">{props.organization.attributes.name}</h3>
        <div>{t('business_id')}: {props.organization.id}</div>
        <div>{t('industry')}: {props.organization.relationships.businessline}</div>
        <div> {t('turnover_class')}  ({t('thousand')} €): {turnover}</div>
        <Website key="website" content={website} />
        <Phone key="phone" content={phone} />
        <Email key="email" content={email} />
      </div>
    );
  }

  if (JSON.stringify(socialMedia).length) {
    column2.push(<div className="d-none d-sm-block"><SocialMedia key="socialMedia" content={socialMedia} /></div>);
  }

  var column3 = [];
  var persons = props.organization.attributes.persons;
  if ( persons && persons.length ) {
    column3.push(<ContactPerson key="persons" content={persons} />);
  }


  if ( visitingAddresses.length ) {
    column3.push(<VisitingAddress key="visiting" content={visitingAddresses} />);
  }

  if ( branchAddresses.length ) {
    column3.push(<BranchAddress key="branch" content={branchAddresses} />);
  }

  if ( postalAddresses.length ) {
    column3.push(<PostalAddress key="postal" content={postalAddresses} />);
  }
  if ( JSON.stringify(socialMedia).length ) {
    column3.push(<div className="d-sm-none"><SocialMedia key="socialMedia" content={socialMedia} /></div>);
  }


  if ( column2.length || column3.length ) {
    columns.push(
      <div className="col-12 col-xl-7 order-sm-2" key="contacts_details">
        <div className="organization__section organization__detail">
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-7" key="addresses">
              {column2}
            </div>

            <div className="col-12 col-sm-6 col-xl-5" key="contacts">
              {column3}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ( logo && visitingAddresses[0] ) {
    columns.push(
      <div className="col-12 col-xl-5 order-sm-1" key="map">
        {logo ?
          <div className="organization__map-images">
            <div className="organization__images d-none d-sm-flex">
              <img
                src={logo}
                alt=""
              />
            </div>
            <Map content={visitingAddresses[0]}/>
          </div>
        : 
          <div className="organization__map-only">
            <Map content={visitingAddresses[0]}/>
          </div>
        }
      </div>
    );
  }else if(postalAddresses[0]){
     columns.push(
      <div className="col-12 col-xl-5 order-sm-1" key="map">
        {logo ? 
          <div className="organization__map-images">
            <div className="organization__images d-none d-sm-flex">
              <img
                src={logo}
                alt=""
              />
            </div>
            <Map content={postalAddresses[0]}/>
          </div>
        : 
          <div className="organization__map-only">
            <Map content={postalAddresses[0]}/>
          </div>
        }
      </div>
    );
  }else if(branchAddresses[0]){
    console.log(branchAddresses[0]);
     columns.push(
      <div className="col-12 col-xl-5 order-sm-1" key="map">
        {logo ? 
          <div className="organization__map-images">
            <div className="organization__images d-none d-sm-flex">
              <img
                src={logo}
                alt=""
              />: ''}
            </div>
            <Map content={branchAddresses[0]}/>
          </div>
        : 
          <div className="organization__map-only">
            <Map content={branchAddresses[0]}/>
          </div>
        }
      </div>
    );
  }

  return(
    columns.length ? <div className="organization__section organization__details">
      <div className="row g-0">{columns}</div>
    </div> : ''
  );
}
