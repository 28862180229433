import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableBody from '../../Table/TableBody';
import TableRow from '../../Table/TableRow';
import { useTranslation } from 'react-i18next';

import OrganizationTableRow from './OrganizationTableRow';

export default function OrganizationsTable(props) {

  const { t, i18n } = useTranslation();
  var isService = props.filters.is_services.value;
  var tableHeadings = [
    t('company'),
    t('industry'),
     // t('homepage'),
  ];

  if(props.filters.is_services.value == 1){
    tableHeadings  =  [...tableHeadings,t('services')];
  }


  tableHeadings  =  [...tableHeadings,t('turnover_class')];
  tableHeadings  =  [...tableHeadings,t('information')];
  // console.log(props);
  var titleNo = t('no_records_found');
//'Palvelut',
//
  const generateRows = organizations => {
    return organizations.map((organization, index) => {
      return <OrganizationTableRow key={index}  organization={organization}  filters={props.filters} />;
    });
  }

  const generateSpinner = organizations => {
    if (typeof props.organizations !== 'undefined' && props.organizations.length == 0) {
      return <div className="loading"></div>
    } 
  }


  const organizationRows = props.organizations ? generateRows(props.organizations) : [];
  var organizationSpinner = props.organizations ? generateSpinner(props.organizations) : [];

    if(props.organizations.length === 0 && props.isNoData == true){
      organizationSpinner =  <tr><td className="norecord" colspan="5">{titleNo}</td></tr>
    }
  

  return(
    <div className="organizations__table">
      <Table className="organizations">
        <TableHeader>
          <TableRow cells={tableHeadings} isService={props.filters.is_services.value} asHeadings={true} />
        </TableHeader>
        <TableBody>
          {organizationSpinner}
          {organizationRows}
        </TableBody>
      </Table>
    </div>
  );
};
