import { useTranslation } from 'react-i18next';

export default function Phone(props) {
  let link;
  
  const { t, i18n } = useTranslation();

  if ( props.content ) {
    link = <a href={'tel:' + props.content.value} target="_blank">{props.content.value}</a>;
  }

  return(
    <div>
      {link}
    </div>
  );
}
