import { useTranslation } from 'react-i18next';

export default function Description(props) {
  var columns = [];

  const { t, i18n } = useTranslation();

  if(props.content.length != 0)
  {
    columns.push(
      <h3 className="organization__info-title">{t('informationaboutus')}</h3>
    );
  }


  return(
    <div className="organization__info organization__description">
      {columns}
      <div>{props.content[0].description}</div>
    </div>
  );
}
