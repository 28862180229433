import SelectDropdown from '../../Search/Inputs/SelectDropdown';
import { useTranslation } from 'react-i18next';

export default function CategorySelect(props) {
  const options = props.options.map(item => {
    return {label: item.name, value: item.id};
  });

  const { t, i18n } = useTranslation();

  return(
    <SelectDropdown id="categories" label={t('companyform')} name="categories[]" options={options} checked={props.checked}/>
  );
};
