// import { Link } from "react-router-dom";

import Angle from '../../Util/Angle';
import Title from './Parts/Title';

export default function Header(props) {
  const {organization} = props;

  return(
    <header className="organization__header">
      <Title content={organization ? organization.attributes.name : ''} />
    </header>
  );
}
