import SelectDropdown from '../../Search/Inputs/SelectDropdown';
import { useTranslation } from 'react-i18next';

export default function TurnoversSelect(props) {
  const options = props.options.map(item => {
    return {label: item.name, value: item.id};
  });

  const {t, i18n} = useTranslation();

  return(
    <SelectDropdown id="turnovers" label={t('turnover_class')} name="turnovers[]" options={options} checked={props.checked}/>
  );
};

