import { useTranslation } from 'react-i18next';

export default function SocialMedia(props) {
  
  //let items = props.content.map((item, index) => {
  //  return <li key={index}>{item}</li>;
  //});
  
  //let list = items.length ? <ul>{items}</ul> : '';
  var Socialbox = [];
  var columns = [];

  const { t, i18n } = useTranslation();

  if (props.content.facebook != null) {
    columns.push(
      <li key='Facebook'><a className="icon-social icon-facebook" target="_blank" href={'https://' + props.content.facebook}></a></li>
    );
  }

  if(props.content.twitter != null)
  {
    columns.push(
      <li key='Twitter'><a className="icon-social icon-x" target="_blank" href={'https://' + props.content.twitter}></a></li>
    );
  }
  if (props.content.instagram != null) {
    columns.push(
      <li key='Instgram'><a className="icon-social icon-instagram" target="_blank" href={'https://' + props.content.instagram}></a></li>
    );
  }

  if (props.content.youtube != null) {
    columns.push(
      <li key='Instgram'><a className="icon-social icon-youtube" target="_blank" href={'https://' + props.content.youtube}></a></li>
    );
  }
  
  if(columns.length != 0)
  {
    Socialbox.push(
      <h3 className="organization__info-title">{t('socialmedia')}</h3>
      
    );
  }

  return(
    <div className="organization__info organization__social">
      {Socialbox}
      <ul>
        {columns}
      </ul>
    </div>
  );
}
