import config from '../config.json';

export default function fetchLocale(locale, embedfilter_id) {

  let _params = new URLSearchParams(locale);

  let url = [config.url, config.api.base, config.api.version,config.api.endpoints.index, config.api.endpoints.locale].join('/');
  url += '?' + _params.toString();
  url += '&embedfilter_id=' + embedfilter_id;

  return fetch(url,{method: 'GET'});
}