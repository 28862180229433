import config from '../config.json';

export function fetchOrganizations(search,update_locale) {
  let _params = new URLSearchParams(search);

  let url = [config.url, config.api.base, config.api.version, config.api.endpoints.index].join('/');
  url += '?' + _params.toString();
  url += '&update_locale=' +update_locale;

  return fetch(url, {method: 'GET'});
}

export function fetchOrganization(id,update_locale,embedfilter_id) {

  let url =  [config.url, config.api.base, config.api.version, config.api.endpoints.index, id].join('/');
  url += '?update_locale=' +update_locale+'&embedfilter_id='+embedfilter_id;

  return fetch(
    url,
    {method: 'GET'}
  );
}
