import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

export default function NumberTextInput(props) {
  const [value, setValue] = useState(props.value ? props.value : '');
  const {placeholder, className} = props;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const {t, i18n} = useTranslation();


  return(
    <input name={props.name} className={className} type="number" value={value} placeholder={props.placeholder} onChange={handleChange}/>
  );
};
