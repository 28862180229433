import { Fragment, useState,useEffect } from 'react';

import TableRow from '../../Table/TableRow';
import Angle from '../../Util/Angle';
import ScreenReaderText from '../../Util/ScreenReaderText';
import Modal from '../../Util/Modal';

import Header from '../Single/Header';
import Footer from '../Single/Footer';
import Details from '../Single/Details';
import About from '../Single/About';
import {fetchOrganization} from '../../../Api/fetchOrganizations';
import Images from '../Single/Images';
import queryString from 'query-string';
import fetchOrganizationLatLog from '../../../Api/fetchOrganizationLatLog';

export default function OrganizationTableRow(props) {
  const {organization} = props;

  const [toggled, setToggled] = useState(false);
  const [orgnazationSingle, setOrgnazationSingle] = useState('');
  const [detailPart, setDetailPart] = useState('');

  useEffect(() => {

  },[orgnazationSingle]);

  var DetailPart = '';

  const fetchData = () => {
    let code = localStorage.getItem("locale");
    let embedfilter_id = localStorage.getItem("embedfilter_id");

        return fetchOrganization(organization.id, code,embedfilter_id)
          .then(response => response.json())
          .then(json => {
            // Perform additional operations or return the data to be used elsewhere
            return json.data;
          })
          .catch(error => {
            console.error('Error fetching organization:', error);
            throw error; // Rethrow the error to propagate it down the chain
          });
};

  function handleToggle(event) {
    event.preventDefault();
    if(!toggled){
     
      fetchData()
        .then(organization => {
          // Use the fetched data here
          setOrgnazationSingle(organization);
        })
        .catch(error => {
          // Handle errors here
          console.error('Error outside fetchOrganization:', error);
        });
    }
    setToggled(!toggled);
  }

  let buttonId = 'toggle-' + organization.id,
      panelId = 'toggle-panel-' + organization.id;

  let serviceName = '-';
    
   function standardizeUrl(url) {
      // Remove spaces from the URL
      url = url.trim();

      // Check if the URL starts with 'http://' or 'https://'
      if (!url.startsWith('http://') && !url.startsWith('https://') && url != '') {
          // If not, add 'http://'
          url = 'http://' + url.trim();
      }

      return url;
  }


  if(organization.attributes.services[0] != undefined){
     serviceName =  organization.attributes.services[0].names[0].value;
  }
  let website;
  let websiteName = '-';
  if ( organization ) {
    // Contacts
    for (let i = 0; i < organization.attributes.contacts.length; i++) {
      switch (organization.attributes.contacts[i].type.type) {
        case 'website':
          website = organization.attributes.contacts[i];
          break;
      }
    }
  }
  
  if(website != undefined)
  {
    websiteName =  <a href={standardizeUrl(website.value)} ping={website.value} target="_blank">{standardizeUrl(website.value)}</a>;
  }
  
  let logo = organization.logo;


  var cellData = [
    organization.attributes.name,
    organization.relationships.businessline,
    // websiteName,
  ];
  
  if(props.filters.is_services.value == 1){
    cellData  =  [...cellData,serviceName];
  }
  
  cellData  =  [...cellData,organization.attributes.fidetail];

  cellData  =  [...cellData, <button
      id={buttonId}
      className="row-toggle"
      onClick={handleToggle}
      aria-expanded={toggled ? 'true' : 'false'}
      aria-controls={panelId}>
      <ScreenReaderText content="Näytä yrityksen tiedot"/>
      <Angle />
      </button>];

  let organizationPanel;
  if ( toggled ) {
    organizationPanel = <tr className="organizations__panel" aria-labelledby={buttonId} hidden={!toggled}>
        <td colSpan="5">
          <article className="organization">
            {orgnazationSingle == '' ? <div className="loading"></div>:''}
            {orgnazationSingle && ( <Header organization={orgnazationSingle} />)}
            {orgnazationSingle && ( <div className="organization__body">
             {orgnazationSingle && (<Details organization={orgnazationSingle} />)}
             {orgnazationSingle && ( <About organization={orgnazationSingle} />)}
            </div>)}
          </article>
        </td>
      </tr>;
  }

  return(
    <Fragment>
    <TableRow className="organizations__row" cells={cellData} />
    {organizationPanel}
    </Fragment>
  );
};
