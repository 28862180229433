import { Fragment, Component } from 'react';
import React from 'react';
import PageNavigation from '../Components/Pagination/PageNavigation';
import OrganizationsSearch from '../Components/Organizations/Index/OrganizationsSearch';
import PaginatedOrganizations from '../Components/Organizations/Index/PaginatedOrganizations';
import Header from '../Components/Layout/Header';
import { instanceOf } from 'prop-types';
import {fetchOrganization,fetchOrganizations} from '../Api/fetchOrganizations';
import fetchFilters from '../Api/fetchFilters';
import fetchLocale from '../Api/fetchLocale';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

class Home extends Component {


 static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };


  constructor(props) {
    super(props);

    const { cookies } = props;

    var embedfilter_id = '064fa0df-98fb-49ab-85c8-3f8eac14202a';
    var pagetitle = 'companyregister';
    var company_type = 1;

    if(window.embedfilter_id !== undefined){
      embedfilter_id = window.embedfilter_id;
    }

    if(window.pagetitle !== undefined){
      pagetitle = window.pagetitle;
    }

    if(window.company_type !== undefined){
      company_type = window.company_type;
    }

    if(localStorage.getItem("locale") == null){
         localStorage.setItem("locale", 'fi')
         i18n.changeLanguage('fi');
    }
      localStorage.setItem("embedfilter_id", embedfilter_id);

    this.state = {
      organizations: {
        items: [],
        pages: {},
        links: {},
        meta: {},
        cus_links: {},
      },
      filters: {
        businesslineTypes: [],
        businesslines: [],
        categories: [],
        services: [],
        turnovers: [],
        citys: [],
        is_industry: 0,
        is_company_form: 0,
        is_services: 0,
        is_municipality: 0,
        is_turnover_class: 0,
        is_zip_code: 0,
      },
      filtersLoaded: false,
      search: {
        businesslineTypes: [],
        businesslines: [],
        categories: [],
        embedfilter_id: embedfilter_id,
        company_type: company_type,
        pagetitle: pagetitle,
        update_locale: false,
        services: [],
        keywords: [],
        pincodes: [],
        turnovers: '',
        cityoffice: [],
      },
      locale: localStorage.getItem("locale") || 'fi',
      isLoading: false,
      embedfilter_id:embedfilter_id,
      isNoData: false,
      total: 'loading',
      messageTotal: i18n.t('searches_in_total'),
      searchPagination: false
    };



    const setSearchCheckboxState = (form, selector, searchProperty) => {
      const _checkboxes = form.querySelectorAll(selector);
      const _values = [];

      for (var i = 0; i < _checkboxes.length; i++) {
        if ( ! _checkboxes[i].checked ) {
          continue;
        }
        _values.push(_checkboxes[i].value);
      }

      this.state.search[searchProperty] = _values;
    }

    const setSearchKeywordState = (form, selector, searchProperty) => {
      const _input = form.querySelector(selector);
      this.state.search[searchProperty] = _input.value;
    }

    const setSearcRadioState = (form, selector, searchProperty) => {
      const _checkboxes = form.querySelectorAll(selector);
      const _values = [];

        for (var i = 0; i < _checkboxes.length; i++) {
          if ( ! _checkboxes[i].checked ) {
            continue;
          }
    
          this.state.search[searchProperty] = _checkboxes[i].value;
        }
    }

    this.handleSearch = (event) => {
      event.preventDefault();

      if(this.state.filters.is_company_form.value != undefined && this.state.filters.is_company_form.value == 1){
        setSearchCheckboxState(event.target, '[name="businesslineCategories[]"]', 'businesslineCategories');
      }

      if(this.state.filters.is_industry.value != undefined && this.state.filters.is_industry.value == 1){
        setSearchCheckboxState(event.target, '[name="businesslines[]"]', 'businesslines');
      }

      if(this.state.filters.is_turnover_class.value != undefined && this.state.filters.is_turnover_class.value == 1){
        setSearchCheckboxState(event.target, '[name="turnovers[]"]', 'turnovers');
      }

      if(this.state.filters.is_company_form.value != undefined && this.state.filters.is_company_form.value == 1){
          setSearchCheckboxState(event.target, '[name="categories[]"]', 'categories');
      }

      if(this.state.filters.is_zip_code.value != undefined && this.state.filters.is_zip_code.value == 1){
        setSearchKeywordState(event.target, '[name="pincodes"]', 'pincodes');
      }

      if(this.state.filters.is_services.value != undefined && this.state.filters.is_services.value == 1){
      setSearchCheckboxState(event.target, '[name="services[]"]', 'services');
      }

      if(this.state.filters.is_municipality.value != undefined && this.state.filters.is_municipality.value == 1){
        setSearchCheckboxState(event.target, '[name="cityoffice[]"]', 'cityoffice');
      }

      setSearchKeywordState(event.target, '[name="keywords"]', 'keywords', true);

      this.setState({searchPagination: true});
      this.handleFetchOrganizations(1);

    }

    this.handleFetchFilters = () => {
      if ( this.state.filtersLoaded ) {
        return;
      }

      fetchFilters(embedfilter_id)
        .then(response => response.json())
        .then(json => {
          this.setState({
            filters: json.data,
          });
        });
    }

    this.handleReload = () => {
      // Reload the page
      window.location.reload();
   };

    this.handleFetchOrganization = (id) => {
      if ( this.state.isLoading ) {
        return;
      }

      // this.setState({
      //   isLoading: true,
      // });

      let code = localStorage.getItem("locale");

      let _localeParams = {'code':code};

      // i18n.changeLanguage(code);

      fetchOrganization(id,code)
        .then(response => response.json())
        .then(json => {
            this.setState({
              organization: json.data,
              // isLoading: false,
            });
        });

      return this.state.organization;
    };

    this.handleFetchLocale = (embedfilter_id) => {
      if ( this.state.isLoading ) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      let code = localStorage.getItem("locale");

      let _localeParams = {'code':code};

      // i18n.changeLanguage(code);

      fetchLocale(_localeParams,embedfilter_id)
        .then(response => response.json())
        .then(json => {
            cookies.set('locale',json.code, { path: '/' });
            localStorage.setItem("locale", json.code)
            i18n.changeLanguage(json.code);

            this.setState({
              localList: json.data,
              messageTotal: i18n.t('searches_in_total'),
              isLoading: false,
            });
        });

    }

    this.handleLocale = (locale) =>{

      this.setState({
        locale_id:locale
      });

      if ( this.state.isLoading ) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      let _searchParams = this.state.search;
      _searchParams.page = this.state.organizations.page;
      _searchParams.locale_id = locale;
      var update_locale = 1;

      cookies.set('locale',locale, { path: '/' });
      localStorage.setItem("locale", locale)
      this.setState({ locale:locale });
      i18n.changeLanguage(locale);

      fetchOrganizations(_searchParams,update_locale,embedfilter_id)
        .then(response => {
          if ( ! response.ok ) {
            throw new Error(`HTTP error ${response.status}`);
          }

          return response.json();
        })
        .then(json => {
          let organizations = this.state.organizations.pages;
          organizations[this.state.organizations.page] = json.data;

          if(organizations.length < 1){
              this.setState({
                isNoData:true,
              });
          }

          this.setState({
            organizations: {
              pages: organizations,
              links: json.links,
              cus_links: json.cus_links,
              meta: json.meta,
            },
            isLoading: false,
            total:json.meta.total
          });

          if(this.state.searchPagination){
            this.setState({searchPagination: false});
          }
        })
        .catch(error => console.log('Error', error));

        this.handleFetchLocale(this.state.search.embedfilter_id);
        this.handleFetchOrganizations(this.state.page);

    }

    this.handleFetchOrganizations = (pageNumber) => {
      // if ( this.state.organizations.pages[pageNumber] ) {
      //   return this.state.organizations.pages[pageNumber];
      // }

      if ( this.state.isLoading ) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      let _searchParams = this.state.search;
      _searchParams.page = pageNumber;
      _searchParams.locale_id = this.state.locale;
      var update_locale = 0;

      fetchOrganizations(_searchParams,update_locale)
        .then(response => {
          if ( ! response.ok ) {
            throw new Error(`HTTP error ${response.status}`);
          }

          return response.json();
        })
        .then(json => {
          let organizations = this.state.organizations.pages;
          
          organizations[pageNumber] = json.data;

           if(json.data.length < 1){
              this.setState({
                isNoData:true,
              });
            }

          this.setState({
            organizations: {
              pages: organizations,
              links: json.links,
              cus_links: json.cus_links,
              meta: json.meta,
            },
            isLoading: false,
            total:json.meta.total
          });
            if(this.state.searchPagination){
              this.setState({searchPagination: false});
            }
        })
        .catch(error => console.log('Error', error));
    }
  }

  componentDidMount() {
    this.handleFetchFilters();
    this.handleFetchLocale(this.state.search.embedfilter_id);
    this.handleFetchOrganizations(1);
  }


  render() {
    return(
  // console.log(embedfilter_id);
      <Fragment>
        <Header localList={this.state.localList} onSubmit={this.handleLocale} onFilterSubmit={this.handleFetchFilters}  company_type={this.state.search.company_type}  pagetitle={this.state.search.pagetitle} />
        <OrganizationsSearch onSubmit={this.handleSearch} OnReset={this.handleReload} filters={this.state.filters} state={this.state.search}/>
        <PageNavigation
          isLoading={this.state.isLoading}
          data={this.state.organizations}
          isNoData={this.state.isNoData}
          filters={this.state.filters}
          page={1}
          perPage={1}
          shownPages={1}
          PaginatedComponent={PaginatedOrganizations}
          beforePageChange={this.handleFetchOrganizations}
          search={this.state.searchPagination}
          />
        <p className="vendor-count">{this.state.messageTotal} : {this.state.total}</p>
      </Fragment>
    );
  }

}

export default withCookies(Home);
