import { useTranslation } from 'react-i18next';

export default function Services(props) {
  let items = props.content.map((item, index) => {
    return <li key={index}>{item.names[0].value}</li>;
  });

  const { t, i18n } = useTranslation();

  let list = items.length ? <ul>{items}</ul> : '';
  return(
    <div className="organization__info organization__services">
      <h3 className="organization__info-title">{t('services')}</h3>
      {list}
    </div>
  );
}
