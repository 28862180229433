export default function TableRow(props) {
  const generateCells = items => {
    return items.map((item, index) => <td key={index}>{item}</td>);
  }

  const generateHeadings = items => {
    return items.map((item, index) => <th key={index}>{item}</th>);
  }

  const cells = props.asHeadings === true ? generateHeadings(props.cells) : generateCells(props.cells);

  return(
    <tr className={props.className ? props.className : ''}>{cells}</tr>
  );
};
