import { useState } from 'react';
import Checkbox from './Checkbox';

export default function SelectDropdown(props) {
  const checked = props.checked ? props.checked : [];

  const [toggled, setToggled] = useState(false);

  const options = props.options.map((item, index) => {
    return <li key={index} className="">
      <Checkbox
        name={props.name}
        label={item.label}
        value={item.value}
        checked={checked.indexOf(item.value) > -1}
        />
      </li>
  });

  function handleToggle(event) {
    event.preventDefault();
    setToggled(!toggled);
  }

  let buttonId = 'toggle-' + props.id,
      panelId = 'toggle-panel-' + props.id;

  return(
    <div className="dropdown">
      <button className="btn btn-outline-primary dropdown-toggle w-100 text-start" type="button" id={buttonId} data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
        <span className="label-t">{props.label ? props.label : 'Valitse'}</span>
        <span className="icon"><svg width="1.4em" height="1.6em" viewBox="0 0 14 16" aria-hidden="true"><path fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M7.007,1.749v12.495l-5.256-5.27 M12.251,8.979L7.016,14.25"/></svg></span>
      </button>
      <ul className="dropdown-menu" aria-labelledby={buttonId}>
        {options}
      </ul>
    </div>
  );
}
