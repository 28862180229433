import SelectDropdown from '../../Search/Inputs/SelectDropdown';
import { useTranslation } from 'react-i18next';

export default function CityOfficeSelect(props) {
  const options = props.options.map(item => {
    return {label: item.name, value: item.id};
  });

  const {t, i18n} = useTranslation();

  return(
    <SelectDropdown id="cityoffice" label={t('municipality')} name="cityoffice[]" options={options} checked={props.checked}/>
  );
};
