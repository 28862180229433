import { useTranslation } from 'react-i18next';

export default function PostalAddress(props) {
  var care_of = props.content[0].careOf;
  var street1 = props.content[0].street1;
  var street2  = props.content[0].street2;
  var postcode  = props.content[0].postcode;
  var country  = props.content[0].office_city_name;

  const { t, i18n } = useTranslation();

  /*let addresses = props.content.map((address, index) => {
    let lines = [];
    for (var line in address) {
      if ( 'type' === line || (!address[line] || address[line].length === 0) ) {
        continue;
      }
      lines.push(<p key={line} className={line}>{address[line]}</p>);
    }

    return <address key={index}>{lines}</address>;
  });*/

  return(
    <div className="organization__info organization__address organization__address--postal">
      <h3 className="organization__info-title">{t('postaladdress')}</h3>
      {/*addresses*/}{care_of}
      {street1 } {street2} <br />
      {postcode} {country}
    </div>
  );
}
