import { useTranslation } from 'react-i18next';

export default function Website(props) {
  let link;


  function standardizeUrl(url) {
      // Remove spaces from the URL
      url = url.trim();

      // Check if the URL starts with 'http://' or 'https://'
      if (!url.startsWith('http://') && !url.startsWith('https://') && url != '') {
          // If not, add 'http://'
          url = 'http://' + url.trim();
      }

      return url;
  }

  if ( props.content ) {
    link = <a href={standardizeUrl(props.content.value)} target="_blank">{standardizeUrl(props.content.value)}</a>;
  }

  const { t, i18n } = useTranslation();

  return(
    <div>
      {link}
    </div>
  );
}
