import { useTranslation } from 'react-i18next';

export default function VendorLink(props) {

  const { t, i18n } = useTranslation();

  return(
    <p className="vendor-link">
      <a href="https://www.artcloud.fi">{t('service_application')}</a>
    </p>
  );
};
