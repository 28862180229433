import { Fragment, useState, } from 'react';

export default function PageNavigation({
  onFetchOrganization,
  isLoading,
  page,
  data,
  dataOrganization,
  isNoData,
  filters,
  perPage,
  shownPages,
  PaginatedComponent,
  beforePageChange,
  afterPageChange,
  search
}) {

  // const [pages] = useState(Math.round(data.pages.length / perPage));
  const [currentPage, setCurrentPage] = useState(page);
  if(page != currentPage && search){
    setCurrentPage(page);
  }

  function firstPage() {
    changePage(1);
  }

  function prevPage() {
    changePage(currentPage - 1);
  }

  function nextPage() {
    changePage(currentPage + 1);
  }

  function lastPage() {
    changePage(data.meta.last_page);
  }

  function changePage(pageNumber) {
    if ( beforePageChange ) {
      beforePageChange(pageNumber);
    }

    setCurrentPage(pageNumber);

    if ( afterPageChange ) {
      afterPageChange(pageNumber);
    }
  }

  function clickPageLink(event) {
    changePage(
      Number(event.currentTarget.textContent)
    );
  }

  function getPaginatedItems() {
    return data.pages[currentPage] ? data.pages[currentPage] : [];
  }

  // function getPageLinkGroup() {
  //   let start = Math.floor((currentPage - 1) / shownPages * shownPages);
  //   return new Array(shownPages).fill().map((item, index) => start + index + 1);
  // }

  function isCurrentLink(pageNumber) {
    return currentPage == pageNumber;
  }

  function pageLinkClassNames(pageNumber) {
    let classNames = ['pagination__link'];

    if ( isCurrentLink(pageNumber) ) {
        classNames.push('current');
    }else{
        classNames.push('deactive-link');
    }

    return classNames.join(' ');
  }
  function pageLinks() {
    let linkButtons = [];

    if  (data.cus_links !== undefined && data.cus_links.length > 1) {
        data.meta.links = data.cus_links
    }

    if ( currentPage !== 1 && data.links.first ) {
      linkButtons.push(<button type="button" className="pagination__link" onClick={firstPage}>&#171;</button>);
    } else {
      linkButtons.push(<button type="button" className="pagination__link" disabled>&#171;</button>);
    }

    if ( currentPage !== 1 && data.links.prev ) {
      linkButtons.push(<button type="button" className="pagination__link" onClick={prevPage}>&#8249;</button>);
    } else {
      linkButtons.push(<button type="button" className="pagination__link" disabled>&#8249;</button>);
    }

    if ( data.meta.links ) {
      // TODO: filter out default next & prev
      for (let i = 0; i < data.meta.links.length; i++) {
        if ( isNaN(data.meta.links[i].label) ) {
          if ( ! data.meta.links[i].url && data.meta.links[i].label == '...' ) {
            linkButtons.push(
              <button type="button" className="pagination__link" disabled>{data.meta.links[i].label}</button>
            );
          }
          continue;
        }

        linkButtons.push(
          <button type="button" className={pageLinkClassNames(data.meta.links[i].label)} onClick={clickPageLink}>{data.meta.links[i].label}</button>
        );
      }
    }

    if ( currentPage !== data.meta.last_page && data.links.next ) {
      linkButtons.push(<button type="button" className="pagination__link" onClick={nextPage}>&#8250;</button>);
    } else {
      linkButtons.push(<button type="button" className="pagination__link" disabled>&#8250;</button>);
    }

    if ( currentPage !== data.meta.last_page && data.links.last ) {
      linkButtons.push(<button type="button" className="pagination__link" onClick={lastPage}>&#187;</button>);
    } else {
      linkButtons.push(<button type="button" className="pagination__link" disabled>&#187;</button>);
    }

    return linkButtons;
  }

  return(
    <Fragment>
      <PaginatedComponent isLoading={isLoading} isNoData={isNoData} filters={filters}  items={getPaginatedItems()} />
      <nav className="pagination">
        <ul className="pages">
          {pageLinks().map((item, index) => {
            return <li className="page" key={index}>{item}</li>;
          })}
        </ul>
      </nav>
    </Fragment>
  );

}
