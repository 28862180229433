import React, { useState, useEffect } from 'react';
import Form from '../Search/Form';
import { useTranslation } from 'react-i18next';

export default function Header(props) {
  
  var columns = [];

  const { t, i18n } = useTranslation();

  const {onSubmit} = props;

  var locale = localStorage.getItem("locale") || 'fi';
  
  var company_type = props.company_type;
  var pagetitle = props.pagetitle;
  
  var hederTitle =  <h1></h1>
  if(pagetitle != ''){
    var hederTitle =  <h1>{t(pagetitle)}</h1>
  }

  if (props.localList != undefined) {
    props.localList.map(function(item, i){

        var no_last = props.localList.length - 1;

        let url = "/?locale="+item.code;

        var last_icon = ' | '; 

        if(no_last == i){
          last_icon = '';
        }
        
        if(locale == 'sv' && item.name_sweden != ''){
          var name = item.name_sweden+last_icon;
        
        }else if(locale == 'fi' && item.name_finland != '' ){
          var name = item.name_finland+last_icon;

        }else{
          var name = item.name+last_icon;

        }


        return columns.push(<span href={url} key={item.id}  onClick={() => languageSelected(item.code)} className={classSelected(item.code)}> {name}</span>);
      });
  }

  function languageSelected(code) {
    
    if(locale == code){
      return null;
    }
    props.onSubmit(code); 
    return props.onFilterSubmit();
  }

  function classSelected(code) {
    
    if(locale == code){
      return 'lang-name-selected';
    }

    return 'lang-name';
  }


  return(
    <header className="app__header">
      <div className="row">
        <div className="col-12 col-sm-6">
            {hederTitle}
        </div>
        <div className="col-12 col-sm-6 text-sm-end">
          <p className="lang-box"> {columns}</p>
        </div>
      </div>
      {/* <h1>Businessfinder</h1> */}
    </header>
  );
}
