import Container from '../Util/Container';

export default function Main(props) {
  return(
    <section id="businessfinder" className="app">
      <Container>
        {props.children}
      </Container>
    </section>
  );
}
